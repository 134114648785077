import React from 'react'
import log from '../../static/assets/log.png'
import face from '../../static/assets/face.svg'
import inst from '../../static/assets/inst.svg'
import twi from '../../static/assets/twi.svg'
import you from '../../static/assets/you.svg'


const Footer = class extends React.Component {
  render() {
    return (
      <footer className="site-footer" style={{backgroundColor: '#F7F7F7' }}>
      <div className="content has-text-centered">
          <img
            src={log}
            alt="Kaldi"
            style={{ width: '20em', height: '18em' }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
              <div className="social-icons">
                <a title="facebook" href="https://www.facebook.com/CaroYoga-183518078972158/">
                  <img
                    src={face}
                    alt="Facebook"
                    style={{ width: '2.5em', height: '2.5em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/CaroSambado">
                  <img
                    className="fas fa-lg"
                    src={twi}
                    alt="Twitter"
                    style={{ width: '2.5em', height: '2.5em' }}
                  />
                </a>
                <a title="instagram" href="https://www.instagram.com/carolyogapilates/">
                  <img
                    src={inst}
                    alt="Instagram"
                    style={{ width: '2.5em', height: '2.5em' }}
                  />
                </a>
                <a title="youtube" href="https://www.youtube.com/channel/UCfql-K5DnU35Yw1pKSKEC4Q">
                  <img
                    src={you}
                    alt="youtube"
                    style={{ width: '2.5em', height: '2.5em' }}
                  />
                </a>
                
              </div>
            </div>
          </div>
      </footer>
  )
}
}
export default Footer